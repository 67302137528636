<template>
  <div class="padding-top-10 contain_pay">
    <top :bgWhite="true"></top>
    <div class="content_pay bg-white">
      <div
        class="padding-lr-30 padding-top-20 fs24 solid-bottom padding-bottom-15"
      >
        支付
      </div>
      <div
        class="flex align-center flex-direction justify-center padding-tb-30"
      >
        <div class="">
          <div class="flex align-center">
            <span class="fs16 widtht135 text-right">应付金额：</span>
            <span class="text-e54f">{{ order_money || "0.00" }}元</span>
          </div>
          <div class="padding-tb-30">
            <div class="flex">
              <p class="fs16 widtht135 text-right">选择支付方式：</p>
              <div class="">
                <div
                  class="flex align-center padding-bottom-15"
                  v-for="(item, index) in navList"
                  :key="index"
                  @click="tabOne(item, index)"
                >
                  <img
                    src="../../assets/image/secket.png"
                    v-if="currentIndex == index"
                    class="block-20 margin-right-15"
                  />
                  <img
                    src="../../assets/image/select.png"
                    v-else
                    class="block-20 margin-right-15"
                  />
                  <img :src="item.img" class="block-32 margin-right-10" />
                  <span class="margin-right-15 point padding-tb-5">{{
                    item.name
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="margin-top-60 margin-bottom-40 flex align-center justify-center"
          >
            <span
              class="bg-e54f no-border radius-100 padding-lr-60 padding-tb-10 point text-white"
              @click="submitForm()"
              >立即支付</span
            >
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="请输入支付密码" :visible.sync="dialogVisible" width="30%">
      <pay-code @inputPsw="inputSuccess" ref="paycode"></pay-code>
    </el-dialog>
    <!-- 支付弹窗 -->
    <el-dialog
      title="打开微信扫一扫，扫描下方二维码完成支付"
      :visible.sync="qrcodeVisible"
      width="30%"
    >
      <div
        class="flex align-center justify-center padding-bottom"
        v-if="qrcode"
      >
        <img :src="qrcode" class="block-200" />
      </div>
    </el-dialog>
    <!-- 支付弹窗  end -->
  </div>
</template>

<script>
import payCode from "@/components/pay-code/pay-code.vue";
import top from "@/components/top";
import bottom from "@/components/bottom";
export default {
  name: "authen",
  components: {
    bottom,
    top,
    payCode,
  },
  data() {
    return {
      url: "", //
      timer: null,
      qrcode: "", //微信付二维码
      qrcodeVisible: false, //二维码显隐
      dialogVisible: false,
      currentIndex: 0, //选择的索引
      dialogTableVisible: false, //是否展示协议
      order_money: "0.00", //价格
      body: "", //会员权益
      isconfirm: 0, // 是否从拼团跳转
      navList: [
        {
          id: 1,
          name: "余额支付",
          payType: "balance",
          img: require("../../assets/image/balance.png"),
        },
        {
          id: 2,
          name: "微信支付",
          payType: "wechat",
          img: require("../../assets/image/wechat.png"),
        },
        {
          id: 3,
          name: "支付宝支付",
          payType: "zfb",
          img: require("../../assets/image/zfb.png"),
        },
      ],
    };
  },
  mounted() {
    console.log(this.$route.query, 33333);
    this.order_sn = this.$route.query.order_sn;
    this.order_money = this.$route.query.order_money;
    this.isconfirm = this.$route.query.isconfirm;
  },
  methods: {
    // 输入密码完成
    inputSuccess(e) {
      console.log(e);
      let password = e;
      // 余额支付
      this.paymentByBalance(e);
    },
    // 选择支付方式
    tabOne(item, index) {
      this.currentIndex = index;
    },
    // 协议弹框
    toTermsPage() {
      this.dialogTableVisible = true;
    },
    // 立即支付
    submitForm(val) {
      if (this.currentIndex == 0) {
        // 余额支付
        this.paymentByBalanceIsSetPass();
      } else if (this.currentIndex == 1) {
        // 微信支付
        this.paymentWechat();
      } else if (this.currentIndex == 2) {
        let return_url = this.$route.query.return_url;
        console.log(
          `https://www.xcrck.com/index/pay/h5alipay?order_sn=${this.order_sn}&return_url=${return_url}`
        );
        // 支付宝支付
        window.location.href = `https://www.xcrck.com/index/pay/h5alipay?order_sn=${this.order_sn}&return_url=${return_url}`;
      }
    },
    close(val) {
      this.dialogVisible = false;
    },
    /**
     * @function
     * @description 余额支付判断是否设置了支付密码
     */
    paymentByBalanceIsSetPass() {
      this.ajax(
        "post",
        "/v1/5f64a4d364b44",
        {
          pay_money: this.order_money,
        },
        (res) => {
          if (res.code == 1) {
            if (res.data.falg == 1) {
              this.dialogVisible = true;
            } else {
              this.$message.info(res.msg);
              this.$router.push({
                path: "/setting",
                query: {
                  type: 1,
                },
              });
            }
          } else {
            this.$message.info(res.data.msg);
          }
        }
      ).catch((err) => {
        this.loading = false;
      });
    },
    /**
     * @function
     * @description 余额支付
     */
    paymentByBalance(password) {
      this.ajax(
        "post",
        "/v1/5e489f45a051d",
        {
          order_sn: this.order_sn,
          pay_password: password,
        },
        (res) => {
          console.log(res);
          if (res.code == 1) {
            this.$message.success(res.msg);
            if (this.isconfirm == 1) {
              this.$router.push({
                path: "/pay-success",
                query: {
                  order_sn: this.order_sn,
                },
              });
            } else {
              this.$router.go(-1);
            }
          } else {
            this.dialogVisible = false;
            this.$refs.paycode.clearPsw();
            this.$message.error(res.msg);
          }
        }
      ).catch((err) => {
        this.loading = false;
      });
    },
    /**
     * @function
     * @description 微信支付
     */
    paymentWechat() {
      this.ajax(
        "post",
        "/v1/61a19e332e39e",
        {
          order_sn: this.order_sn,
        },
        (res) => {
          if (res.code == 1) {
            // 轮询
            if (res.data) {
              this.qrcode = res.data;
              this.qrcodeVisible = true;
              if (!this.timer) {
                this.timer = setInterval(() => {
                  this.payStatusPoll();
                }, 1000);
              }
            } else {
              this.$message.error(res.msg);
            }
          } else {
            this.$message.error(res.msg);
          }
        }
      ).catch((err) => {
        this.$message.error("服务器错误，请重新");
      });
    },
    /**
     * @function
     * @description 支付状态轮询
     */
    payStatusPoll() {
      this.ajax(
        "post",
        "/v1/61a1cdc7dc8dc",
        {
          order_sn: this.order_sn,
        },
        (res) => {
          if (res.code == 1) {
            clearInterval(this.timer);
            this.timer = null;
            this.$message.success("支付成功");
            if (this.isconfirm == 1) {
              this.$router.push("/pay-success");
            } else {
              this.$router.go(-1);
            }
          } else {
          }
        }
      ).catch((err) => {
        clearInterval(this.timer);
        this.timer = null;
        this.$message.error("服务器错误，请重新");
      });
    },
  },
};
</script>

<style scoped>
.contain_pay {
  width: 100vw;
  height: 100vh;
  background-color: #f8f8f8;
  overflow: auto;
}

.content_pay {
  width: 80%;
  height: auto;
  margin: 80px auto;
}

.avatar {
  width: 200px;
  height: 126px;
  display: block;
}

/deep/.el-input {
  width: 320px;
  height: 36px;
}
</style>
